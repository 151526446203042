<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onCreateMenu" />
    <MenuForm
      :errors="violations"
      :values="item"
      ref="createForm"
      refForm="createForm"
      :parent="this.parent"
      :loading="isLoading"
    />
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import MenuForm from "../../components/menu/Form.vue";
import Loading from "../../components/Loading";
import Toolbar from "../../components/Toolbar";
import CreateMixin from "../../mixins/CreateMixin";
import { mapGetters } from "vuex";

const servicePrefix = "Menu";

const { mapFields } = createHelpers({
  getterType: "menu/getField",
  mutationType: "menu/updateField",
});

export default {
  name: "MenuCreate",
  servicePrefix,
  mixins: [CreateMixin],
  components: {
    Loading,
    Toolbar,
    MenuForm,
  },
  data() {
    return {
      item: {},
      parent: {},
    };
  },
  computed: {
    ...mapFields(["error", "isLoading", "created", "deleted", "violations"]),
    ...mapGetters("menu", ["find"]),
  },
  methods: {
    ...mapActions("menu", {
      create: "create",
    }),
    onCreateMenu() {
      if (this.$route.params.parent) {
        this.$refs.createForm.item.parent = this.$route.params.parent;
      }
      this.onSendForm();
    },
  },
  created() {
    this.parent = this.$route.params.parent
      ? this.find(this.$route.params.parent)
      : {};
  },
};
</script>
